import React, { Component } from 'react'
import './HamburgerIcon.css'
export default class HamburgerIcon extends Component {
    render() {
        return (
            <div className="hamburger">
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
            </div>
        )
    }
}
